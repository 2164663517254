import React, { createContext, useContext, useReducer } from 'react';

const AppContext = createContext();

const initialState = {
  headerTitle: 'Dashboard',
  field2: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'WALLET_BALANCE':
      return { ...state, walletBalance: action.payload };
    
    default:
      return state;
  }
}

export function AppProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
